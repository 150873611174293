@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "blacker";
    src: url("./assets/fonts/BlackerProDis-Lt.woff2");
}

@font-face {
    font-family: "peridot";
    src: url("./assets/fonts/peridot-pe-variable.woff2");
}

@font-face {
    font-family: "peridot-1";
    src: url("./assets/fonts/peridot-pe-variable-1.woff2");
}

.text-blacker {
    font-family: "blacker";
}

.text-peridot {
    font-family: "peridot";
}

.text-peridot-1 {
    font-family: "peridot-1";
}

.link-text {
    font-size: var(--text-base--small);
    line-height: 120%;
    letter-spacing: 0
  }
  
  .link1 {
    display: flex;
    transition-property: all;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.16,1,.3,1)
  }
  
  @media (min-width: 640px) {
    .group:hover .link1 {
        --tw-translate-y:-100%;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
  }
  
  .link2 {
    position: absolute;
    inset: 0;
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-property: all;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.16,1,.3,1)
  }
  
  @media (min-width: 640px) {
    .group:hover .link2 {
        --tw-translate-y:0px;
        transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }
  }

  html {
    --cursor-color: #333
  }
  
  html.dark-mode {
    --cursor-color: #fff
  }

  .line-list {
    li {
        position: relative;
        &:after {
            content: "";
            width: 0px;
            height: 1px;
            background-color: black;
            transition: all 3s ease-in-out;
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
        }
    }
  }

  .overlay-left {
    transition:  all 3s ease-in-out !important;
    z-index: 1;
  }

  .overlay-bottom {
    transition:  all 2s ease-in-out !important;
    z-index: 1;
  }

  .aos-animate {
    &.overlay-left {
      width: 0%;
    }
    &.overlay-bottom {
      height: 0%;
    }
    .line-list {
        li {
            &:after {
                width: 100%;
            }
        }
      }
  }

  .gallery-wrapper {
    position: relative;
    z-index: 10;
  }
  
  .gallery {
    display: flex;
    height: 100%;
    width: max-content; /* Đảm bảo chiều rộng linh động theo nội dung */
  }
  
  .gallery-item {
    min-width: 25vw; /* Mỗi item chiếm hết chiều rộng màn hình */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }